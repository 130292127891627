<template>
    <v-container fluid min-height="550">
        <v-row>
            <v-sheet color="#92D050" class="mx-auto ma-5 pa-2 rounded-xl" width="600">
            <v-card color="#0E0856" class="ma-2 rounded-xl">
                <v-card-title>
                    Reset Password
                </v-card-title>
                <v-sheet class="pa-3">
                    <v-form>
                        <v-text-field variant="outlined" :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="password"
                            color="primary" label="Current Password" ></v-text-field>
                        <v-text-field :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="newPassword"
                            color="primary" label="New Password" variant="outlined"></v-text-field>
                        <v-text-field :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="newPassword"
                            color="primary" label="Retype New Password" variant="outlined"></v-text-field>
                    </v-form>
                </v-sheet>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="outlined" class="rounded-xl ma-2">Save <v-icon
                            class="ma-2">mdi-key-change</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-sheet>
        </v-row>
    </v-container>

</template>
<script>
export default {
    data() {
        return {
            password: '',
            newPassword: ''
        }
    }
}
</script>