import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import axios from "axios";
axios.defaults.baseURL = "https://rms-backend-6hdz.onrender.com/";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const vuexPersist = new VuexPersistence({
  key: "user", // unique key for saving state properties in the browser storage
  storage: window.localStorage, // select the type of storage used (eg - localStorage, sessionStorage, etc)
  reducer: (state) => ({ user: state.user, token: state.token }), // select the state properties that you want persisted
});

export default createStore({
  plugins: [vuexPersist.plugin],
  state: {
    token: localStorage.getItem("authToken") || null,
    user: localStorage.getItem("user") || null,
    newlocation: "",
    locations: [],
    branch: []

  },
  getters: {
    isAuthenticated: (state) => state.token,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getBranches: (state) => state.locations,
    rc_number: (state) => state.user.rc_number,
  },
  mutations: {
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    setNewLocation(state, locate) {
      state.newlocation = locate;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("authToken", token);
    },
    setUser(state, user) {
      state.user = user;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      localStorage.clear();
    },
  },
  actions: {
    async addClient(
      { commit, state },
      { name, password, rc_number, email, phone, role, groupId }
    ) {
      try {
        const client = await axios.post(
          "/api/createclient",
          {
            name,
            groupId,
            password,
            rc_number,
            email,
            phone,
            role,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        commit("newClient", client);
      } catch (err) {
        console.error(err);
      }
    },
    
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post("/api/login", {
          username,
          password,
        });
        const { token, user } = response.data;
        commit("setToken", token);
        commit("setUser", user);
      } catch (error) {
        console.error("Login error:", error);
        throw error;
      }
    },
    async getbranches({ commit, state }) {
      if (!state.token) return;

      const { data: locations } = await axios.get(
        "api/getlocations",
        this.getters.rc_number,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("SET_LOCATIONS", locations);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
});
