<template>
    <v-container min-height="600" max-height="900">
        <v-row>
            <v-col md="3">
                <h1>Admin Dashboard</h1>
            </v-col>
            <v-col>
                <v-text-field :loading="loading" append-inner-icon="mdi-magnify" density="compact" label="Search"
                    variant="solo" hide-details single-line @click:append-inner="onClick"></v-text-field>
            </v-col>
        </v-row>
        <v-sheet color="#0E0856" class="rounded-xl pa-2 d-flex justify-space-between">
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <v-card-title>
                    Manage Users
                </v-card-title>
                <v-card-text>
                    <v-img height="200" :src="require('../assets/follower.png')"></v-img>

                    <!--Add Client Dialog Starts Here-->
                    <v-dialog v-model="clientadd" max-width="400" persistent>
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" @click="clientadd = true" size="small" variant="text"
                                outlined class="ma-2 rounded-lg">Add New Client <v-icon size="20"
                                    class="ml-2">mdi-account-plus-outline</v-icon></v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                            <v-card v-if="!loader" color="#0E0856" class="rounded-xl">
                                <v-card-title>
                                    <div class="pa-2 d-flex justify-spacer-between">Add New User
                                        <v-spacer></v-spacer>
                                        <v-icon>mdi-account-plus</v-icon>
                                    </div>
                                </v-card-title>
                                <v-sheet>
                                    <form ref="addClient" @submit="addNewClient">
                                        <v-text-field v-model="newUser.name" label="Client Name">
                                        </v-text-field>
                                        <v-text-field v-model="newUser.groupId" label="Group ID">
                                        </v-text-field>
                                        <v-text-field v-model="newUser.rc_number" label="RC Number">
                                        </v-text-field>
                                        <v-text-field v-model="newUser.phone" label="Phone">
                                        </v-text-field>
                                        <v-text-field v-model="newUser.email" label="Email">
                                        </v-text-field>
                                        <v-text-field v-model="newUser.password" type="password" label="Password">
                                        </v-text-field>
                                        <v-select v-model="newUser.role" label="Role"
                                            :items="['Admin', 'Client', 'Technician', 'User']">
                                        </v-select>
                                    </form>
                                </v-sheet>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="warning" class="rounded-xl" @click="isActive.value = false">
                                        Close
                                    </v-btn>
                                    <v-btn type="Submit" @click="addNewClient(), clientadd = false" class="rounded-xl">
                                        Create
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <!--Add Client Dialog Ends Here-->

                    <!--Loader-->
                    <v-dialog v-model="loader" max-width="400" persistent>
                        <v-card class="py-2" color="#0E0856" elevation="12" rounded="xl">
                            <v-card-title>
                                <div class="d-flex justify-space-between">
                                    Please wait <v-icon>mdi-cogs</v-icon>
                                </div>
                            </v-card-title>
                            <v-sheet class="d-flex align-center" min-height="55">
                                <v-list-item title="Processing Request...">
                                    <template v-slot:append>
                                        <v-progress-circular color="success" indeterminate="disable-shrink" size="42"
                                            width="4"></v-progress-circular>
                                    </template>
                                </v-list-item>
                            </v-sheet>
                        </v-card>
                    </v-dialog>

                    <!--View All Users-->
                    <v-dialog v-model="viewClient" max-width="800">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" size="small" variant="text" class="ma-2 rounded-lg">View All
                                <v-icon size="20" class="ml-2">mdi-eye-outline</v-icon></v-btn>
                        </template>
                        <v-card class="rounded-xl" color="#0E0856">
                            <v-card-title>
                                <div class="pa-2 d-flex align-center justify-space-between">
                                    View All Clients <v-icon>mdi-briefcase-account</v-icon>
                                </div>
                            </v-card-title>
                            <v-sheet min-height="250">
                                <v-table>
                                    <thead>
                                        <tr>
                                            <th>RC Number</th>
                                            <th>Company Name</th>
                                            <th>Total Locations</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="client in clients" :key="client">
                                        <tr>
                                            <td>{{ client.rc_number }}</td>
                                            <td>{{ client.name }}</td>
                                            <td>{{ client.rc_number }}</td>
                                            <td>{{ countBranches['client.rc_number'] }}</td>
                                            <td><v-btn size="small" class="rounded-xl" @click="showBranch(client)">View</v-btn></td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-sheet>
                            <v-card-actions>
                                <v-btn class="rounded-xl pa-2" color="warning" @click="viewClient = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!--View All Users Ends-->
                </v-card-text>
            </v-card>
            <v-divider vertical></v-divider>
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <v-card-title>
                    Locations
                </v-card-title>
                <v-card-text>
                    <v-img height="200" :src="require('../assets/map.png')"></v-img>
                    <!--Location Dialog Starts-->
                    <v-dialog v-model="locationDialog" max-width="400" max-height="700" persistent>
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" size="small" variant="text" class="ma-2 rounded-lg">Add
                                Location <v-icon size="20" class="ml-2">mdi-account-plus-outline</v-icon></v-btn>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-card class="rounded-xl" color="#0E0856" title="Add Location"
                                append-icon="mdi-crosshairs-gps">
                                <v-sheet>
                                    <v-form v-model="form" @submit.prevent="addLocation()">
                                        <!--Branch Name-->
                                        <v-text-field v-model="formData.branch" label="Branch Name"
                                            required></v-text-field>
                                        <!--RC_Number-->
                                        <v-text-field v-model="formData.rc_number" label="RC Number"
                                            required></v-text-field>
                                        <!--Device ID-->
                                        <v-text-field v-model="formData.deviceid" :rules="imeiRule" :counter="16" label="Device ID (IMEI)"
                                            required></v-text-field>
                                        <!--Branch Name-->
                                        <v-text-field v-model="formData.lat" :counter="10" label="Latitude"
                                            required></v-text-field>
                                        <!--Branch Name-->
                                        <v-text-field v-model="formData.lng" label="Longitude" required></v-text-field>
                                        <!--Generator Size-->
                                        <v-text-field v-model="formData.gen_one_size"
                                            label="Generator Size (e.g 200kVA)" required></v-text-field>
                                        <!--Storage Tank Size-->
                                        <v-text-field v-model="formData.storage_size" label="Storage Tank Size"
                                            required></v-text-field>
                                    </v-form>
                                </v-sheet>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="rounded-xl" color="error" text="Close"
                                        @click="isActive.value = false"></v-btn>
                                    <v-btn class="rounded-xl" color="success" text="Create" @click="addLocation()"
                                        type="submit"></v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <!--Location Dialog Ends-->
                    <v-btn size="small" variant="text" class="ma-2 rounded-lg">View All <v-icon size="20"
                            class="ml-2">mdi-eye-outline</v-icon></v-btn>
                </v-card-text>
            </v-card>
            <v-divider vertical></v-divider>
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <v-card-title>
                    Notifications
                </v-card-title>
                <div>
                    <v-btn disabled variant="text" class="mr-1 pb-1" icon><v-icon>mdi-fuse-alert</v-icon></v-btn>
                    <v-btn variant="text" class="mr-1 pb-1" icon><v-icon>mdi-tanker-truck</v-icon></v-btn>
                    <v-btn disabled variant="text" class="mr-1 pb-1"
                        icon><v-icon>mdi-power-plug-battery-outline</v-icon></v-btn>
                    <v-btn variant="text" class="mr-1 pb-1" icon><v-icon>mdi-gauge-empty</v-icon></v-btn>
                </div>
            </v-card>
        </v-sheet>

        <v-card class="pa-5">
            Users Activity Trends Goes Here
        </v-card>
        <v-snackbar class="rounded-xl" :color="color" v-model="snackbar" :timeout="timeout">
            {{ text }}
        </v-snackbar>
    </v-container>
</template>
<script>
import api from '@/services/api';
export default {
    data() {
        return {            
            count: '',
            numberOfBranches: '',
            color: '',
            form: false,
            imeiRule: [
                (value) => {
                    if (value?.length > 15 && /[0-9-]+/.test(value)) return true;
                    return "IMEI number must be 16 digits.";
                },
            ],
            snackbar: false,
            text: "",
            timeout: 2500,
            locationDialog: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters'
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            clients: [],
            viewClient: false,
            name: '',
            clientadd: false,
            email: '',
            password: '',
            phone: '',
            role: '',
            loader: false,
            dialog: false,
            newUser:{
                name: '',
                groupId: '',
                rc_number: '',
                phone: '',
                email: '',
                password: '',
                role: '',
            },
            formData: {
                branch: '',
                rc_number: '',
                lng: '',
                lat: '',
                gen_one_size: '',
                storage_size: '',
                deviceid: '',                
            }
        }
    },
    computed: {
         getTotalBranches(rcNumber){    
            console.log(rcNumber)                   
            return api.getNumberOfLocations(rcNumber);               
        },
    },
    async mounted() {
        this.getClients();
        //this.countBranches();
    },

    methods: {   
        showBranch(rc_number){
            console.log(rc_number);
        },     
        async countBranches(rc_number){
            try {
                const numberOfBranches = await api.getNumberOfLocations(rc_number);
                const count = numberOfBranches.data
                console.log('number of branches', count);
                return count;
            } catch (error) {
                console.log(error.message)
            }
        },
        async getClients() {
            try {
                const response = await api.getClients();
                this.clients = response.data
            } catch (error) {
                console.log(error);
            }
        },
        async addNewClient(){
            this.$refs.addClient.reset();
            try {
                this.loader = true;
                this.$refs.addClient.reset();
                await api.addClient(this.newUser);
                this.color = '#92D050';
                this.text = "Client added successfully.";
                this.snackbar = true;           
                this.loader = false;
            } catch (error) {
                console.error(error);
                this.color = 'error'
                console.log(error);
                this.text= "Adding Location Failed";
                this.snackbar = true;    
                this.loader = false;
            }
        },
        async addClient() {
            this.loader = true;
            this.$store.dispatch("addClient",
                {
                    name: this.name,
                    password: this.password,
                    phone: this.phone,
                    email: this.email,
                    rc_number: this.rc_number,
                    role: this.role,
                    groupId: this.groupId
                }
            ).then(() => {
                this.loader = false;
                this.clientadd = false;
                this.color = '#92D050';
                this.text = "New Client added successfully.";
                this.loader = false;
            }).catch(error => {
                this.color = 'error'
                console.log(error);
                this.text= "Adding Client Failed";
                this.snackbar = true; 
            })
        },
        async addLocation() {
            this.loader = true
            this.locationDialog = false
            try {
                await api.addLocation(this.formData);
                this.color = '#92D050';
                this.text = "Location added successfully.";
                this.snackbar = true;           
                this.loader = false;
            } catch (error) {
                this.color = 'error'
                console.log(error);
                this.text= "Adding Location Failed";
                this.snackbar = true;    
                this.loader = false;
            }
        }
    }
}
</script>
<style scoped></style>