<template>
    <v-container fluid>
        <DashBoard />
    </v-container>
</template>
<script>
import DashBoard from '@/views/DashBoard.vue';
export default {
    components: {
        DashBoard
    },
    data() {
        return {

        }
    }
}
</script>