<template>
  <v-table height="70vh">
    <thead>
      <tr>
        <th class="text-left">
          Location
        </th>
        <th class="text-left">
          Power Source
        </th>
        <th class="text-left pt-4 d-none d-md-block">
          Last Check
        </th>
        <th class="text-left">
          View
        </th>
      </tr>
    </thead>
    <tbody v-for="location in branchName" :key="location.id">
      <tr>
        <td>{{ location['device.name'] }}</td>
        <td>
          <v-sheet class="d-flex justify-center rounded-xl" color="#92D050" width="80" v-if="location['din.1'] == true">
            Grid On
          </v-sheet>
          <v-sheet class="d-flex justify-center rounded-xl" color="red" width="80" v-if="location['din.2'] == true">
            Gen On
          </v-sheet>
          <v-sheet class="d-flex justify-center rounded-xl" color="red" width="80" v-if="location['din.3'] == true">
            Gen 2 On
          </v-sheet>
        </td>
        <td class="pt-4 d-none d-md-block">{{ formatTimestamp(location['server.timestamp']) }}</td>
        <td>
          <v-btn size="small" class="rounded-lg" color="#0E0856" @click="showLocationDetail(location.ident)">
            <v-icon color="#92D050">mdi-monitor-eye</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>

</template>
<script>
import api from '@/services/api';
export default {
  data() {
    return {
      locations: [],
      cleanData: [],
      channel: [],
      branchName: [],
    }
  },
  async mounted() {
    this.getLocations();
    this.getChannelMessages();
    this.getTelemetry();
  },
  computed: {
    isGridActive() {
      return this.cleanData ? this.cleanData["din.1"] : false;
    },
    isGenOneActive() {
      return this.data ? this.data["din.2"] : false;
    },
    isGenTwoActive() {
      return this.data ? this.data["din.3"] : false;
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      return date.toLocaleString('en-US', options);
    },
    async getTelemetry() {
      try {
        const response = await api.getAllDevices();
        this.branchName = response.data.result
      } catch (err) {
        return err;
      }
    },
    async getLocations() {
      try {
        const response = await api.getGroup();
        this.locations = response.data.result;
      } catch (error) {
        console.error("Location list error", error)
      }
    },
    showLocationDetail(deviceId) {
      this.$emit('show-details', deviceId)
    },
    async getChannelMessages() {
      try {
        const response = await api.getChannelMessages();
        this.channel = response.data.result
        const sorted = this.separateAndSortDevicesData(this.channel)
        this.cleanData = sorted;
      } catch (err) {
        console.log("Unable to fetch Channel Data", err)
      }
    },
    
  }
}
</script>
<style scoped>
.green-background {
  background-color: #92D050;
  color: white;
}
</style>