<template>
    <div>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'basic-bar'
                },
                xaxis: {
                    categories: ['Allen 1', 'Ademola Adetokunbo', 'Oba Akran', 'Idumagbo', 'Ketu', 'Ikeja City Mall', 'Festac 1',
                        'Oshodi']
                }
            },
            series: [{
                name: 'Grid',
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            },
            {
                name: 'Generator',
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            }
            ]
        }
    }
}
</script>