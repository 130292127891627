<template>
    <div class="storage-tank">
      <svg width="250" height="210" viewBox="0 0 200 300">
        <!-- Tank Body -->
        <ellipse cx="100" cy="50" rx="80" ry="20" fill="silver" stroke="#92D050" stroke-width="5" />
        <rect x="20" y="50" width="160" height="200" fill="#7D7C7C" stroke="#92D050" stroke-width="3" />
        <ellipse cx="100" cy="250" rx="80" ry="20" fill="#D4AF37" stroke="#92D050" stroke-width="5" />
  
        <!-- Liquid Level (Fill) -->
        <clipPath id="liquid-clip">
          <rect
            x="20"
            :y="calculateLiquidYPosition"
            width="160"
            :height="calculateLiquidHeight"
          />
        </clipPath>
        <ellipse cx="100" cy="250" rx="80" ry="20" fill="#D4AF67" clip-path="url(#liquid-clip)" />
        <rect x="20" y="50" width="159" height="200" fill="#D4AF37" clip-path="url(#liquid-clip)" />
        <ellipse cx="100" :cy="250 - calculateLiquidHeight" rx="80" ry="20" fill="#D4CF37" />
  
      </svg>
      <div class="tank-info">
        <p class="text-overline">Current Level: <span class="font-weight-medium">{{ currentVolume }} L</span></p>
        <p class="text-overline">Tank Capacity:  <span class="font-weight-medium">{{ totalCapacity }} L</span></p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentVolume: {
        type: Number,
        required: true,
      },
      totalCapacity: {
        type: Number,
        required: true,
      },
    },
    computed: {
      calculateLiquidHeight() {
        return (this.currentVolume / this.totalCapacity) * 200;
      },
      calculateLiquidYPosition() {
        return 250 - this.calculateLiquidHeight;
      },
    },
  };
  </script>
  
  <style scoped>
  .storage-tank {
    text-align: center;
  }
  
  .tank-info {
    margin-top: -10px;
    font-size: 14px;
  }
  
  ellipse,
  rect {
    transition: all 0.8s ease-out;
  }
  </style>
  