<template>
  <v-container min-height="550" fluid>
    <h1>Reports</h1>
    <v-row>
      <v-col md="4">
        <v-sheet color="#0E0856" class="pa-3 rounded-xl">
          <v-card class="rounded-xl pa-2">
            <v-card-title>
              Weekly Trends
            </v-card-title>
            <v-card-text>
              <v-sheet height="250">
                <WeeklyUsage />
              </v-sheet>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col md="4">
        <v-sheet color="#92D050" class="pa-3 rounded-xl">
          <v-card class="rounded-xl pa-2">
            <v-card-title>
              Monthly Trends
            </v-card-title>
            <v-card-text>
              <v-sheet height="250">
                <MonthlyUsage/>
              </v-sheet>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col md="4">
        <v-sheet color="#92D050" class="pa-3 rounded-xl">
          <v-card color="#0E0856" class="rounded-xl">
            <v-card-title>
              Upcoming Features
            </v-card-title>
            <v-card-text>
              <v-sheet height="250">

              </v-sheet>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import SampleAnimation from '@/components/Charts/SampleAnimation.vue';
import api from '@/services/api'
import MonthlyUsage from '@/components/Charts/MonthlyUsage.vue';
import WeeklyUsage from '@/components/Charts/WeeklyUsage.vue';

export default {
  components: {
    //SampleAnimation,
    WeeklyUsage,
    MonthlyUsage
   
  

  },
  data() {
    return {
      generators: [
                { name: "Gen1 - 150kVA", currentLevel: 12, capacity: 600 },
                { name: "Gen2 - 150kVA", currentLevel: 0, capacity: 600 },
            ],
      refillData: [],
      currentVolume: 1110, // default value
      totalCapacity: 5500, // assuming this is static      
    };
  },
  async mounted() {
    this.fetchMessages()
  },
  methods: {
    async fetchMessages() {
      try {
        const response = await api.summary();
        this.refillData = response.data.result
      } catch (error) {
        console.error("Error Fetching Device Message")
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      return date.toLocaleString('en-US', options);
    },
    convertToHHMMSS(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${this.padToTwoDigits(hrs)}:${this.padToTwoDigits(mins)}:${this.padToTwoDigits(secs)}`;
    },
    padToTwoDigits(num) {
      return num.toString().padStart(2, '0');
    },
  },

}
</script>

<style scoped>
.green-background {
  background-color: #92D050;
  color: white;
}

.red-background {
  background-color: red;
  color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
  /* Equivalent to the 'lg' size in Tailwind CSS */
}

.table-responsive {
  overflow-x: auto;
}

.custom-table .custom-header {
  background-color: #92D050;
  color: #0E0856;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid white;
}

.custom-table .custom-row td {
  border-left: 2px solid #ddd;
}

.custom-table .custom-row td:first-child {
  border-left: none;
}
</style>