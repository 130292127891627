<template>
  <v-layout>
    <NavBar v-if="isAuthenticated" />
    <v-app-bar v-else app color="#0E0856" dark>
      <!-- Left Section: Logo and Company Name -->
      <v-toolbar-title>
        <div class="d-flex align-center">
          <v-img :src="require('@/assets/volt_icon.png')" alt="Company Logo" contain max-height="60"></v-img>
          <span class="company-name d-none d-md-block">Voltage & Wattage</span>
        </div>

      </v-toolbar-title>
      <v-row></v-row>
      <!-- Spacer pushes the links to the right -->
      <v-spacer></v-spacer>

      <!-- Right Section: Navigation Links -->
      <div class="d-flex align-center">
        <v-btn class="d-none d-md-block" to="">Home</v-btn><span class="d-none d-md-block">|</span>
        <v-btn class="d-none d-md-block" to="">About</v-btn><span class="d-none d-md-block">|</span>
        <v-btn class="d-none d-md-block" to="">Updates</v-btn><span class="d-none d-md-block">|</span>
        <v-btn class="d-none d-md-block" to="">Contact</v-btn>
      </div>
    </v-app-bar>
    <v-main style="min-height: 500px;">
      <RouterView />
      <v-footer class="d-flex flex-column">
        <v-card color="#92D050" class="d-flex w-100 align-center px-4">
          <span class="d-none d-md-block">Ensuring Effective Power Asset Monitoring, Reporting and Verification (MRV)
            Solution for Realtime Insights</span>
          <v-spacer></v-spacer>
          <v-btn icon="mdi-facebook" href="https://web.facebook.com/voltageandwattage" class="mx-4" size="small"
            variant="plain"></v-btn>
          <v-btn icon="mdi-twitter" href="https://x.com/voltagewattage" class="mx-4" size="small"
            variant="plain"></v-btn>
          <v-btn icon="mdi-linkedin" href="https://www.linkedin.com/company/103149556/admin/dashboard/" class="mx-4"
            size="small" variant="plain"></v-btn>
          <v-btn icon="mdi-instagram" href="https://www.instagram.com/voltageandwattage" class="mx-4" size="small"
            variant="plain"></v-btn>
        </v-card>
        <v-sheet color="#0E0856" class="pa-5 text-center w-100 d-flex justify-center">
          <div>Voltage & Wattage Ltd © {{ new Date().getFullYear() }}</div>
        </v-sheet>
      </v-footer>
    </v-main>
  </v-layout>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      isLoggedIn: false,
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.user.role;
    }
  }
}
</script>

<style scoped>
.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c3e50;
  /* Dark gray */
  color: #ecf0f1;
  /* Light gray */
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.left-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.company-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.right-section .nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links li a {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #3498db;
  /* Blue */
}
</style>
