<template>
  <v-app-bar color="#0E0856">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row>
       <v-col v-if="user.role === 'Admin'">        
        <v-img height="60" :src="require('../assets/volt_icon.png')"></v-img>        
      </v-col>
      <v-col v-else>     
        <v-img height="60" :src="require('../assets/Stanbic.png')"></v-img>
      </v-col>
       <v-spacer/><v-col md="10"></v-col>
      </v-row>   
     
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" :location="$vuetify.display.mobile ? 'bottom' : undefined" temporary>
    <v-list app>
      <v-list-item v-if="user.role === 'Admin'" to="/management">
        Home
      </v-list-item>
      <v-list-item v-if="user.role === 'Client'" to="/StanbicIBTC">
        Dashboard
      </v-list-item>
      <v-list-item to="/reports">
        Reports
      </v-list-item>
      <v-list-item v-if="user.role === 'Admin'" to="/location">
        Locations
      </v-list-item>
      <v-list-item v-if="user.role === 'Client'" to="/profile">
        Profile
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn color="error" block @click="logoutControl">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapState} from 'vuex';
export default {
  props: {
        selectedLocation: Object,
        deviceId: Object,
    },
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: 'Dashboard',
        to: '/dashboard',
      }      
    ],
  }),
  methods: {
    logoutControl() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.commit("resetState");
          this.$router.push("/");
        })
        .catch((err) => {
          console.error("Error logging out:", err);
        });
    },
    goBack() {
            this.$back('back');
        }
  },
  computed: {
    user() {
      const user = JSON.stringify(localStorage.getItem('user'));
      const role = user.user;
      return role;
    },
    ...mapState(['user'])
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>

<style scoped>
.logo {
  margin-left: 8px; /* Adjust this margin as needed */
  align-self: center; /* Ensures the logo aligns vertically in the middle */
}
</style>