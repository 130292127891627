<template>
    <div id="chart">
        <apexchart type="bar" height="250" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import api from '@/services/api';
export default {
    data() {
        return {
            series: [{
                name: "Grid",
                data: []
            }, {
                name: "Generator",
                data: []
            }],
            fill: {
                type: "solid",
                colors: ["#000111"]
            },
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 430
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: false,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                },
            },
        }
    },
    async mounted() {
        await this.getWeeklyReport();
    },
    methods: {
        updateData() {
            this.series = [{
                name: "Grid",
                data: [44, 55, 41, 64, 22, 43, 21]
            }, {
                name: "Generator",
                data: [53, 32, 33, 52, 13, 44, 32]
            }]
        },
        convertToHHMMSS(seconds) {
            const hrs = Math.floor(seconds / 3600);
            const mins = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
            return `${this.padToTwoDigits(hrs)}:${this.padToTwoDigits(mins)}:${this.padToTwoDigits(secs)}`;
        },
        async getWeeklyReport() {
            try {
                const response = await api.getWeeklyReport(353201350160526);
                const data = response.data.result.map(item => ({
                    x: item['grid'],
                    y: item['gen_combined']
                }));
                this.series = [{
                    name: "Grid",
                    data: data.map(item => item.x)
                }, {
                    name: "Generator",
                    data: data.map(item => item.y)
                }];
                console.log('Report: ', response.data);
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>